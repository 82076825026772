import React, {Component} from 'react';

import style from './index.module.sass';

class Tabber extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leftHeader: true,
            leftContent: true
        }
    }

    toogleHeader = (val) => {
        if (this.state.leftHeader !== val) {
            this.setState({
                leftHeader: val
            });
            setTimeout(() => {
                this.setState({
                    leftContent: val
                })
            }, 200)
        }
    };

    render() {
        const {leftHeader, leftContent} = this.state;
        const {
            leftHeaderText,
            rightHeaderText,
            leftContentItem,
            rightContentItem,
            customTabberClass = ''
        } = this.props;

        let activeHeaderClass = leftHeader ? style.activeLeftHeader : style.activeRightHeader;

        return (
            <div className={`${style.Tabber} ${customTabberClass}`}>
                <header className={activeHeaderClass}>
                    <div onClick={() => {
                        this.toogleHeader(true)
                    }}>{leftHeaderText}
                    </div>
                    <div onClick={() => {
                        this.toogleHeader(false)
                    }}>{rightHeaderText}</div>
                </header>
                {leftContent ? <div style={!leftHeader ? {opacity: '0'} : {}}>{leftContentItem}</div> :
                <div style={leftHeader ? {opacity: '0'} : {}}>{rightContentItem}</div>}
            </div>
        );
    }
}

export default Tabber;
