import React from 'react';
import {render} from 'react-snapshot';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import './index.css';
import './config.sass'

if (process.env.NODE_ENV !== 'development') {
    console.log = () => null;
}

const rootElement = document.getElementById("root");
if (rootElement) {
    rootElement.innerHTML = ''
}

render(<App/>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();


// Принудительная очистка браузерного кэша у пользователя (работает в случае с кэшом воркера)
if (window.caches) {
    window.caches.keys().then(function (keyList) {
        return Promise.all(keyList.map(function (key) {
            window.caches.delete(key);
        }));
    });
}
