import React, { Component } from 'react';

import InputForm from "../InputForm";
import { parseQueryString } from "../../utils";

import style from './index.module.sass';
import car from '../../assets/imgs/car.png'
import car_webp from '../../assets/imgs/webp/car.webp'
import car_m_webp from '../../assets/imgs/webp/mobile/car.webp'
import carMobile from '../../assets/imgs/carMobile2.png'
import carMobile_webp from '../../assets/imgs/webp/carMobile2.webp'
import action from '../../assets/imgs/action.png'
import action_webp from '../../assets/imgs/webp/action.webp'
import actionNew from '../../assets/imgs/actionNew.png'
import actionNew_webp from '../../assets/imgs/webp/actionNew.webp'


let url = document.createElement('a');
url.href = window.location.href;
console.log(parseQueryString(url.search));
let urlParams = parseQueryString(url.search);

let actionPng = action;
let actionWebp = action_webp;

if (urlParams.utm_source && (urlParams.utm_source.toLowerCase() == "yandex" || (urlParams.utm_source.toLowerCase() == "google"))) {
    actionPng = actionNew;
    actionWebp = actionNew_webp;
}

const multiLabels = {
    '1': <h1>Залог ПТС в<br />Санкт-Петербурге</h1>,
    '2': <h1>Займ под залог ПТС в СПб</h1>,
    '3': <h1>Деньги под залог ПТС<br />в СПб</h1>,
    '4': <h1>Ссуда под залог ПТС</h1>,
    '5': <h1>Микрозайм под <br />залог ПТС</h1>,
    '6': <h1>Залог под ПТС</h1>,
    '7': <h1>Займ под залог ПТС</h1>,
    '8': <h1>Займ под ПТС</h1>,
    '9': <h1>Деньги под залог ПТС</h1>,
    '10': <h1>Деньги под ПТС</h1>,
    '11': <h1>Кредит под залог ПТС</h1>,
    '12': <h1>Кредит под ПТС</h1>,
    '13': <h1>Залог ПТС в СПб</h1>,
};

class Loans extends Component {

    constructor(props) {
        super(props);
        let url = document.createElement('a');
        url.href = window.location.href;
        console.log(
            url.href + '\n' +           // the full URL
            url.protocol + '\n' +       // http:
            url.hostname + '\n' +       // site.com
            url.port + '\n' +           // 81
            url.pathname + '\n' +       // /path/page
            url.search + '\n' +         // ?a=1&b=2
            url.hash                    // #hash
        );

        console.log(parseQueryString(url.search));
        let urlParams = parseQueryString(url.search);

        let headerName = <h1>Автозаймы под ПТС</h1>;
        if (urlParams.utm_multi && multiLabels[urlParams.utm_multi]) {
            headerName = multiLabels[urlParams.utm_multi];
        }

        this.state = {
            headerName
        }
    }

    render() {

        return (
            <section className={style.Loans}>
                <picture >
                    <source type={"image/webp"}
                            srcSet={`${carMobile_webp} 500w`}/>
                    <img src={carMobile} alt="МАШИНА"/>
                </picture>
                <picture>
                    <source type={"image/webp"}
                            srcSet={`${car_m_webp} 500w,  ${car_webp} 6000w`}/>
                    <img src={car} alt="МАШИНА"/>
                </picture>
                <picture className={style.action}>
                    <source type={"image/webp"}
                            srcSet={`${actionWebp} 6000w`}/>
                    <img src={actionPng} alt="МАШИНА"/>
                </picture>

                <div className={style.lighter}/>
                {this.state.headerName}
                <ul>
                    <li><div/>До 1 000 000 ₽ за 60 минут</li>
                    <li><div/>Вы остаетесь при своем автомобиле</li>
                    <li><div/>Без GPS маяка, 2-й ключ не требуется</li>
                </ul>
                <InputForm />
            </section>
        );
    }
}

export default Loans;
