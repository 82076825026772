import React, {Component} from 'react';
import DocsPanel from '../DocsPanel';

import style from './index.module.sass';
import fileImg from '../../assets/imgs/file.png'
import docIcon from '../../assets/imgs/docIcon.png';
import logo from '../../assets/imgs/logo_new.png';

import {parseQueryString} from "../../utils";
import $ from 'jquery';

let url = document.createElement('a');
url.href = window.location.href;
console.log(parseQueryString(url.search));
let urlParams = parseQueryString(url.search);
const isSEOPage = urlParams.utm_source && (urlParams.utm_source.toLowerCase() === "yandex" || urlParams.utm_source.toLowerCase() === "google");


class Info extends Component {

    componentDidMount() {
        /*import("app/components/DocsPanel")
            .then(({ DocsPanel }) => {
              console.log('load DocsPanel');
              this.setState({
                docsPanel: DocsPanel
              })
            })
            .catch(err => {
              console.log(err)
              // Handle failure
            });*/
        $(document).ready(function() {
          console.log('Ready!!!!');
          $('.sub').on('click', function(e) {
            e.preventDefault();
            $(this).next('ul').toggle();
          });
        });
    }

    docs = [
        {
            text: 'Общие условия договора потребительского займа',
            file: '/files/Общие_условия_договора_потребительского_займа_1.docx',
            type: 'DOCX'
        },
        {
            text: 'Базовый стандарт',
            file: '/files/Базовый%20стандарт.pdf',
            type: 'PDF'
        },
        {
            text: 'Правила предоставления микрозаймов',
            file: '/files/new_docs/Правила%20предоставления%20микрозаймов%20от%2023.03.2022.pdf',
            type: 'PDF'
        },
        {
            text: 'Свидетельство о государственной регистрации юридического лица',
            file: '/files/Свидетельство%20о%20государственной%20регистрации%20юридического лица.pdf',
            type: 'PDF'
        },
        {
            text: 'Свидетельство о внесении сведений в государственный реестр микрофинансовых организаций',
            file: '/files/Свидетельство%20о%20внесении%20сведений%20в%20государственный%20реестр%20микрофинансовых%20организаций.pdf',
            type: 'PDF'
        },
        {
            text: 'Свидетельство СРО',
            file: '/files/Свидетельство%20СРО.pdf',
            type: 'PDF'
        },
        {
            text: 'Информация об условиях предоставления',
            file: '/files/Информация%20об%20условиях%20предоставления.pdf',
            type: 'PDF'
        },
        {
            text: 'Политика в отношении обработки персональных данных клиента',
            file: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf',
            type: 'PDF'
        },
        {
            text: 'Рекомендации для подачи обращения',
            file: '/files/Рекомендации%20для%20подачи%20обращения.pdf',
            type: 'PDF'
        },
        {
            text: 'Свидетельство на товарный знак',
            file: '/files/Свидетельство%20на%20ТЗ.pdf',
            type: 'PDF'
        },
        {
            text: 'Реквизиты ООО МКК Капелла',
            file: '/files/Реквизиты%20ООО%20МКК%20Капелла.pdf',
            type: 'PDF'
        },
        {
            text: 'Реквизиты ООО МКК Народная опора',
            file: '/files/Реквизиты%20ООО%20МКК%20Народная%20опора.pdf',
            type: 'PDF'
        },
        {
            text: 'Стоп вирус',
            file: '/files/Стоп%20вирус.pdf',
            type: 'PDF'
        },
        // {
        //     text: 'Новые правила взыскания задолженности',
        //     file: '/files/Новые%20правила%20взыскания%20задолжнности.pdf',
        //     type: 'PDF'
        // },
        {
            text: 'Схема взаимосвязи общества и лиц',
            file: '/files/Схема%20взаимосвязи%20общества%20и%20лиц.pdf',
            type: 'PDF'
        },
        {
            text: 'Список аффилированных лиц',
            file: '/files/Список%20аффилированных%20лиц.pdf',
            type: 'PDF'
        },
        {
            text: 'Дополнение к информации',
            file: '/files/Дополнение%20к%20%20информации.pdf',
            type: 'PDF'
        },
        {
            text: 'Дополнение к условиям',
            file: '/files/Дополнение%20к%20%20условиям.pdf',
            type: 'PDF'
        },
        {
            text: 'Права заемщика',
            file: '/files/Права%20заемщика.pdf',
            type: 'PDF'
        },
        {
            text: 'Памятка потребителей по урегулированию споров через финансового уполномоченного',
            file: '/files/Памятка%20потребителей%20по%20урегулированию%20споров%20через%20финансового%20уполномоченного.pdf',
            type: 'PDF'
        },
    ];

    // docs2 = [
    //     {
    //         text: 'Лицензия ЦБ РФ 1903504009230',
    //         file: '/files/Лицензия%20ЦБ%20РФ%201903504009230.pdf',
    //         type: 'PDF'
    //     },
    //     {
    //         text: 'Карточка предприятия',
    //         file: '/files/Карточка%20предприятия.pdf',
    //         type: 'PDF'
    //     }
    // ];

    anotherDocs = [
      [
        {
          text: 'Общие условия договора потребительского займа',
          url: '/files/new_docs/Общие условия договора потребительского займа.pdf',
          items: [],
        },
        {
          text: 'Общие условия потребительского займа “Архив предыдущих редакций”',
          items: [
            {
              subtext: 'Общие условия договора потребительского займа редакция №4 от 26.07.2021',
              suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №4 от 26.07.2021.docx'
            },
            {
              subtext: 'Общие условия договора потребительского займа редакция №3 от 01.01.2020',
              suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №3 от 01.01.2020.pdf'
            },
            {
              subtext: 'Общие условия договора потребительского займа редакция №2 от 01.07.2019',
              suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №2 от 01.07.2019.pdf'
            },
            {
              subtext: 'Общие условия договора потребительского займа редакция №1 от 22.04.2019',
              suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №1 от 22.04.2019.pdf'
            },
          ],
        },
        {
          text: 'Информация об условиях предоставления, использования и возврата потребительского займа',
          url: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа.pdf',
          items: [],
        },
        {
          text: 'Информация об условиях предоставления, использования и возврата потребительского займа “Архив предыдущих редакций”',
          items: [
            {
              subtext: 'Информация об условиях предоставления, успользования и возврата потребительского займа редакция №3 от 01.01.2020',
              suburl: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа редакция №3 от 01.01.2020.docx'
            },
            {
              subtext: 'Информация об условиях предоставления, успользования и возврата потребительского займа редакция №2 от 01.07.2019',
              suburl: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа редакция №2 от 01.07.2019.pdf'
            },
            {
              subtext: 'Информация об условиях предоставления, успользования и возврата потребительского займа редакция №1 от 22.04.2019',
              suburl: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа редакция №1 от 22.04.2019.pdf'
            },
          ],
        },
        {
          text: 'Правила предоставления микрозаймов',
          url: '/files/new_docs/Правила предоставления микрозаймов от 23.03.2022.pdf',
          items: [],
        },
        {
          text: 'Правила предоставления микрозаймов “Архив предыдущих редакций”',
          items: [
            {
              subtext: 'Правила предоставления микрозаймов от 01.01.2020',
              suburl: '/files/new_docs/Правила предоставления микрозаймов от 01.12.2021.pdf'
            },
            {
              subtext: 'Правила предоставления микрозаймов от 01.01.2020',
              suburl: '/files/new_docs/Правила предоставления микрозаймов от 01.01.2020.docx'
            },
            {
              subtext: 'Правила предоставления микрозаймов от 01.07.2019',
              suburl: '/files/new_docs/Правила предоставления микрозаймов от 01.07.2019.pdf'
            },
            {
              subtext: 'Правила предоставления микрозаймов от 22.04.2019',
              suburl: '/files/new_docs/Правила предоставления микрозаймов от 22.04.2019.pdf'
            },
          ],
        },
        {
          text: 'Базовый стандарт совершения операций на финансовом рынке',
          url: '/files/new_docs/Базовый стандарт совершения операций на финансовом рынке.docx',
          items: [],
        },
        {
          text: 'Базовый стандарт по управлению рисками',
          url: '/files/new_docs/Базовый по управлению рисками.docx',
          items: [],
        },
        {
          text: 'Базовый стандарт защиты прав и интересов физических и юридических лиц',
          url: '/files/new_docs/Базовый стандарт защиты прав и интересов физических и юридических лиц.docx',
          items: [],
        },
      ],
      [
        {
          text: 'Устав ООО МКК “Капелла”',
          url: '/files/new_docs/Устав ООО МКК Капелла.pdf',
          items: [],
        },
        {
          text: 'Устав ООО МКК “Капелла” “Архив предыдущих редакций”',
          items: [
            {
              subtext: 'Устав от 26.11.2018',
              suburl: '/files/new_docs/Устав от 26.11.2018.pdf'
            },
            {
              subtext: 'Устав от 31.07.2018',
              suburl: '/files/new_docs/Устав от 31.07.2018.pdf'
            },
          ],
        },
        {
          text: 'Реквизиты ООО МКК “Капелла”',
          url: '/files/new_docs/Реквизиты ООО МКК Капелла.pdf',
          items: [],
        },
        {
          text: 'Список аффилированных лиц',
          url: '/files/new_docs/Список аффилированных лиц.pdf',
          items: [],
        },
        {
          text: 'Схема взаимосвязи общества и лиц',
          url: '/files/new_docs/Схема взаимосвязи общества и лиц.pdf',
          items: [],
        },
        {
          text: 'Политика в отношении обработки персональных данных',
          url: '/files/new_docs/Политика в отношении обработки персональных данных.pdf',
          items: [],
        },
        {
          text: 'Памятка потребителей по урегулированию споров через финансового уполномоченного',
          url: '/files/new_docs/Памятка потребителей по урегулированию споров через финансового уполномоченного.pdf',
          items: [],
        },
        {
          text: 'Правила проведения маркетинговой (рекламой) акции “Приведи друга”',
          url: '/files/new_docs/Правила проведения акции приведи друга.pdf',
          items: [],
        },
        {
          text: 'Заявление о частично досрочном погашении займа',
          url: '/files/new_docs/Заявление_о_частично_досрочном_погашении_займа.docx',
          items: [],
        }
      ]
    ];

    clientsInfoTable = [
        {
          key: 'Банк России:',
          value: 'https://www.cbr.ru/',
          is_link: true
        },
        {
          key: 'Интернет-приемная Банка России:',
          value: 'https://www.cbr.ru/Reception/ ',
          is_link: true
        },
        {
          key: 'Государственный реестр микрофинансовых организаций:',
          value: 'https://www.cbr.ru/microfinance/registry/',
          is_link: true
        },
        {
          key: 'Регистрационный номер записи в государственном реестре микрофинансовых организаций: ',
          value: 'https://cbr.ru/finorg/foinfo/?ogrn=1162468123102',
          is_link: true
        },
        {
          key: 'Дата внесения сведений о юридическом лице в государственный реестр микрофинансовых организаций:',
          value: 'с 22.04.2019',
          is_link: false
        },
        {
          key: 'Обращения, связанные с кредитной историей, можно направить на электронную почту:',
          value: 'creditcar.info@gmail.com',
          is_link: false
        },
    ];

    clientsInfoTableLinks = [
        {
            text: 'Информация о кредитных каникулах для участников специальной военной операции (СВО)',
            url: '/files/new_docs/Информация по кредитным каникулам для участников специальной военной операции (СВО).pdf',
        },
    ];

    sroInfoTable = [
        {
            key: 'Компания:',
            value: 'ООО МКК «Капелла»',
            is_link: false
        },
        {
            key: 'Дата вступления:',
            value: 'с 24.05.2022',
            is_link: false
        },
        {
            key: 'Наименование СРО:',
            value: 'Союз «Микрофинансовый альянс «Институты развития малого бизнеса»',
            is_link: false
        },
        {
            key: 'Адрес:',
            value: 'г. Москва, Полесский проезд 16, стр.1, оф.308',
            is_link: false
        },
        {
            key: 'Телефон:',
            value: '8 (800) 555-24-99',
            is_link: false
        },
        {
            key: 'Официальный сайта СРО:',
            value: 'https://alliance-mfo.ru/',
            is_link: true
        },
    ];

    financeInfoTable = [
        {
          key: 'Место нахождения и почтовый адрес службы обеспечения деятельности финансового уполномоченного:',
          value: '119017, г. Москва, Старомонетный пер., дом 3',
          is_link: false
        },
        {
          key: 'Номер телефона службы обеспечения деятельности финансового уполномоченного:',
          value: '8 (800) 200-00-10',
          is_link: false
        },
        {
          key: 'Адрес официального сайта финансового уполномоченного в информационно-телекоммуникационной сети "Интернет":',
          value: 'https://finombudsman.ru/',
          is_link: true
        },
    ];

    render() {
        console.log(process.env.REACT_APP_STATUS);

        return (
            <section className={style.Info}>
                <div className={style.companyInfo}>
                  <div>
                    <img src={logo} alt="" />
                    <div className={style.companyTitle}>Общество с ограниченной ответственностью Микрокредитная компания “КАПЕЛЛА”.</div>
                  </div>
                  <div>
                    <p>ООО МКК «Капелла». Регистрационный номер записи в государственном реестре микрофинансовых организаций № 1903504009230, ОГРН 1162468123102, ИНН 2465156710</p>
                    <p>Юр. адрес РФ, 660135, Красноярский Край, г. Красноярск, ул. Взлетная, д.5, стр.1, оф. 66</p>
                    <p>Ген.дир. Манежина А.Е. дата назначения на должность: 15.09.2020 г.</p>
                  </div>
                </div>
                <DocsPanel />
                {/*<div className={style.fileList}>*/}
                    <h2>Документы</h2>
                    <div className={style.docsBlock}>
                      {[0, 1].map((c, u) => (
                        <ul className={style.docsList} key={u}>
                          {this.anotherDocs[c].map((item, i) => {
                            let {url = '', text = '', items = []} = item;
                            if (items.length == 0) {
                              return (
                                <li key={i}>
                                  <a
                                    href={url}
                                    className={url ? '' : 'disabled'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={docIcon} alt="" />
                                    <span>{text}</span>
                                  </a>
                                </li>
                              )
                            }
                            else {
                              return (
                                <li key={i}>
                                  <a className="sub">
                                    <img src={docIcon} alt="" />
                                    <span>{text}</span>
                                  </a>
                                    <ul className={style.docsSubList}>
                                      {items.map(({ suburl, subtext }, j) => (
                                        <li key={j}>
                                          <a
                                            href={suburl}
                                            className={suburl ? '' : 'disabled'}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img src={docIcon} alt="" />
                                            <span>{subtext}</span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      ))}
                    </div>
                    {/*<ul>
                        {this.docs.map(({file, text, type = 'PDF'}, i) => <li key={i}  className={file ? '' : 'disabledLink'}>
                            <img src={fileImg} alt=""/>
                            <a href={file} target="_blank" rel="noopener noreferrer">
                                <span>{text}</span>({type})</a>

                        </li>)}
                    </ul>*/}
                {/*</div>*/}
        <div className={style.infoListGroup}>
          <div className={style.infoListBlock}>
                <h2 className={style.clientsInfoTitle}>Информация для клиентов</h2>
                {/*<table className={style.infoTable}>
                  {this.clientsInfoTable.map(({ key, value, is_link }, i) => (
                    <tr key={i}>
                      <td>{key}</td>
                      {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
                    </tr>
                  ))}
                </table>*/}
                <ul className={style.infoList}>
                  {this.clientsInfoTable.map(({ key, value, is_link }, i) => (
                    <li key={i}>
                      <div>{key}</div>
                      {is_link ? <div><a href={value}>{value}</a></div> : <div>{value}</div>}
                    </li>
                  ))}
                    {this.clientsInfoTableLinks.map(({ text, url }, i) => (
                        <li key={i}>
                            <div><a href={url}>{text}</a></div>
                        </li>
                    ))}
                </ul>
          </div>
          <div className={style.infoListBlock}>
                <h2 className={style.sroInfoTitle}>Информация о членстве МФО в СРО:</h2>
                {/*<table className={style.infoTable}>
                  {this.sroInfoTable.map(({ key, value, is_link }, i) => (
                    <tr key={i}>
                      <td>{key}</td>
                      {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
                    </tr>
                  ))}
                </table>*/}
                <ul className={style.infoList}>
                  {this.sroInfoTable.map(({ key, value, is_link }, i) => (
                    <li key={i}>
                      <div>{key}</div>
                      {is_link ? <div><a href={value}>{value}</a></div> : <div>{value}</div>}
                    </li>
                  ))}
                </ul>
          </div>
        </div>
        <div className={style.infoLastBlock}>
                <h2 className={style.financeInfoTitle}>Информация о финансовом уполномоченном:</h2>
                <p>Потребитель финансовых услуг вправе направить обращение финансовому уполномоченному в соответствии со статьями 15-19 Федерального закона от 4 июня 2018 года N 123-ФЗ "Об уполномоченном по правам потребителей финансовых услуг".</p>
                {/*<table className={style.infoTable}>
                  {this.financeInfoTable.map(({ key, value, is_link }, i) => (
                    <tr key={i}>
                      <td>{key}</td>
                      {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
                    </tr>
                  ))}
                </table>*/}
                <ul className={style.infoList}>
                  {this.financeInfoTable.map(({ key, value, is_link }, i) => (
                    <li key={i}>
                      <div>{key}</div>
                      {is_link ? <div><a href={value}>{value}</a></div> : <div>{value}</div>}
                    </li>
                  ))}
                </ul>
        </div>
                <div className={style.footNote}>
                    <p>
                        Условия займа под залог ПТС. Услуга предоставляется резидентам РФ, возраст от 21 до 72 лет.
                        Минимальный срок займа 61 день, максимальный 36 месяцев.
                        Максимальная сумма займа – до 1 000 000 рублей, минимальная процентная ставка – от 1,5% до 7% в месяц.
                        Максимальная годовая процентная ставка за год составляет 85,167%.
                        В случае просрочки начисляется неустойка (пени) из расчета 20% годовых (0,05479% в день) от суммы просроченной задолженности (основного долга) за каждый календарный день просрочки, начиная с даты, следующей за датой очередного не исполненного в срок платежа. Начисление процентов – ежемесячно. Наличие полиса ОСАГО и/или КАСКО – не обязательно.
                        Досрочное погашение без штрафов и комиссий. Дополнительная комиссия за выдачу отсутствует.
                        Пример: при сумме займа 50 000 рублей, годовой ставкой 85,167% на срок 2 года, ежемесячный платеж составит 4 396,83 рублей.
                        Услуга предоставляется ООО МКК «Капелла», ОГРН 1162468123102, ИНН 2465156710, Регистрационный номер записи в государственном реестре микрофинансовых организаций № 1903504009230.
                        660135, г. Красноярск ул. Взлетная, 5, стр1. офис 66.
                        Обращаем ваше внимание, что информация, размещенная на сайте, носит информационный характер и не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации. Конкретные условия займа будут определяться отдельно при заключении договора.
                    </p>
                </div>
                {/*<div className={style.condition}>
                    <article>
                        <p>
                            Условия займа под залог ПТС. Услуга предоставляется резидентам РФ, возраст от 21 до 72 лет.
                            Минимальный срок займа 61 день, максимальный 36 месяцев.
                            Максимальная сумма займа – до 1 000 000 рублей, минимальная процентная ставка – от 1,5% до 7% в месяц.
                            Максимальная годовая процентная ставка за год составляет 85,167%.
                            В случае просрочки начисляется неустойка (пени) из расчета 20% годовых (0,05479% в день) от суммы просроченной задолженности (основного долга) за каждый календарный день просрочки, начиная с даты, следующей за датой очередного не исполненного в срок платежа. Начисление процентов – ежемесячно. Наличие полиса ОСАГО и/или КАСКО – не обязательно.
                            Досрочное погашение без штрафов и комиссий. Дополнительная комиссия за выдачу отсутствует.
                        </p>
                        <p>
                            Пример: при сумме займа 50 000 рублей, годовой ставкой 85,167% на срок 2 года, ежемесячный платеж составит 4 396,83 рублей.
                            Услуга предоставляется ООО МКК «Капелла», ОГРН 1162468123102, ИНН 2465156710, Регистрационный номер записи в государственном реестре микрофинансовых организаций № 1903504009230.
                            660135, г. Красноярск ул. Взлетная, 5, стр1. офис 66.
                            Обращаем ваше внимание, что информация, размещенная на сайте, носит информационный характер и не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации. Конкретные условия займа будут определяться отдельно при заключении договора.
                        </p>
                    </article>
                </div>*/}
            </section>
        );
    }
}

export default Info;
