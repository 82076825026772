import React, {Component} from 'react';
import {Modal} from 'antd';

import style from './index.module.sass';

import eagle from '../../assets/imgs/eagle.png'
import mir from '../../assets/imgs/mir.png'
// import SupportBlock from "../SupportBlock";
//import Carousel from "../Carousel";
import Button from "../Button";
import eagleWebp from "../../assets/imgs/webp/eagle.webp";

class About extends Component {
    constructor(props) {
        super(props);
        this.aboutRef = React.createRef();
        this.state = {
            visible: false,
            carousel: ''
        }
    }

    componentDidMount() {

        const listener = () => {

            let getBodyScrollTop = function () {
                const el = document.scrollingElement || document.documentElement;
                return el.scrollTop
            };

            if (!this.state.carousel) {
                const elementRect = this.aboutRef.current.getBoundingClientRect()
                let elemOffset = Math.round(elementRect.top + document.body.getBoundingClientRect().top - elementRect.height);

                if (getBodyScrollTop() > elemOffset) {
                    import("../Carousel")
                        .then(({Carousel}) => {
                            this.setState({
                                carousel: Carousel
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            // Handle failure
                        });
                }
            }
        };
        listener()
        document.addEventListener('scroll', listener);
        document.addEventListener('touchmove', listener);
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    render() {
        let Carousel = this.state.carousel || (() => <div/>);
        return (
            <div className={style.About} ref={this.aboutRef}>
                <Carousel/>
                <div>
                    <p>
                        Creditcar — это современная компания, которая Вам предложит займ быстро и безопасно. Честно и
                        просто. Альтернатива, при которой транспортное средство остается в собственности, так же в
                        пользовании своего владельца. Вы оставляете только ПТС, дополнительные документы не требуются.
                    </p>
                    <p>
                        Мы работаем строго в соответствии с законодательством Российской Федерации. Максимально
                        прозрачно и честно. Рассматриваем заявки с любой кредитной историей, разрабатываем
                        индивидуальный подход к каждому клиенту. Вам не потребуется подтверждать свои доходы, искать
                        поручителей. Мы не обзваниваем Ваших коллег по работе, партнеров по бизнесу, родственников и
                        друзей. Мы понимаем, что финансовая ситуация сугубо личное дело. <span onClick={this.showModal}>Мы готовы заключить сделку в любой день недели, без выходных, с возможностью досрочного погашения<span>*</span>.</span> За
                        досрочное закрытие договора не предусмотрены штрафы, и производится перерасчет суммы исходя из
                        фактического срока пользования займом.
                    </p>
                    <div><a href={'/files/Общие%20условия%20договора%20потребительского%20микрозайма.pdf'}
                            target="_blank" rel="noopener noreferrer">Посмотреть пример договора</a> (PDF)
                    </div>
                </div>
                {/*<div>
                    <article>
                        <picture>
                            <source type={"image/webp"}
                                    srcSet={`${eagleWebp}`}/>
                            <img src={eagle} alt="МФО"/>
                        </picture>
                        <div>
                            <h4>Под надзором ЦБ</h4>
                            <p>
                                Состоим в реестре<br/>Центрального Банка<br/>
                                Российской Федерации
                                <br/>№ 1903504009230<br/>от 22 апреля 2019г.
                            </p>
                        </div>
                    </article>
                    <article>
                        <img src={mir} alt="МИР"/>
                        <div>
                            <h4>Состоим в СРО «МиР»</h4>
                            <p>№ 24 001087<br /> дата регистрации 19.07.2019 </p>
                        </div>
                    </article>
                </div>*/}
                {/*<SupportBlock formMetricsId={3}/>*/}
                <Modal
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleOk}
                >
                    <section className={style.info}>
                        <p>Заключение сделки в субботу и воскресение возможно по предварительной записи.</p>
                        <div onClick={this.handleOk}>
                            <Button styleName={'default'}>ОК</Button>
                        </div>
                    </section>
                </Modal>
            </div>
        );
    }
}

export default About;
