import React, { Component } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Modal } from 'antd';

import style from './index.module.sass';
import logo from '../../assets/imgs/logo.png';
import logo_m_webp from '../../assets/imgs/webp/mobile/logo.webp';
import logo_webp from '../../assets/imgs/webp/logo.webp';
import phone from '../../assets/imgs/iPhone.png';
import Button from '../Button';
import SendModalForm from "../SendModalForm";

class Header extends Component {
    state = {
        sticky: false,
        showButton: false,
        visible: false,
        visibleForm: false,
        formAlreadyShown: false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showModalForm = () => {
        this.setState({
            visibleForm: true,
            formAlreadyShown: true
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
            visibleForm: false
        });
    };

    componentDidMount() {
        //setTimeout(() => {if (!this.state.formAlreadyShown) this.showModalForm()}, 11000);
        if (window.pageYOffset > 40) {
            if (!this.state.sticky) {
                this.setState({
                    sticky: true
                });
                setTimeout(() => this.setState({showButton: true}), 100)
            }
        } else {
            if (this.state.sticky) {
                this.setState({
                    sticky: false
                });
                setTimeout(() => this.setState({showButton: false}), 400)
            }
        }
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 40) {
                if (!this.state.sticky) {
                    this.setState({
                        sticky: true
                    });
                    setTimeout(() => this.setState({showButton: true}), 100)
                }
            } else {
                if (this.state.sticky) {
                    this.setState({
                        sticky: false
                    });
                    setTimeout(() => this.setState({showButton: false}), 400)
                }
            }
        });
    }

    render() {
        const { sticky, showButton, visible, visibleForm } = this.state;
        const { visibleNotification } = this.props;
        let stickyClass = sticky ? style.stickyHeader : '';
        let wrapLink = (name = '', text = '') => <Link
            className={name}
            to={name}
            spy={true}
            smooth={true}
            duration={1000} >{text}</Link>;

        let marginClass = (!sticky && visibleNotification) ? style.showMargin : '';
        let marginModalClass = (!sticky && visibleNotification) ? style.showModalMargin : '';

        return (
            <header className={`${style.Header} ${stickyClass} ${marginClass}`}>
                <div className={style.logo} onClick={() => scroll.scrollToTop()}>
                    <picture >
                        <source type={"image/webp"}
                                srcSet={`${logo_m_webp} 500w,  ${logo_webp} 6000w`}/>
                        <img src={logo} alt={'CREDITCAR'}/>
                    </picture>
                </div>
                <ul>
                    <li>{wrapLink('Steps', 'Как получить займ')}</li>
                    <li>{wrapLink('CreditInfo', 'Условия')}</li>
                    <li>{wrapLink('Contacts', 'Контакты')}</li>
                </ul>

                {sticky ? <Button
                    className={style.headerButton}
                    style={showButton ? {opacity: 1} : {}}
                    onClick={() => this.showModalForm()}
                >ПЕРЕЗВОНИТЬ МНЕ</Button> : ''}
                <div className={style.contacts}>
                    <div>
                        <img src={phone} alt="Телефон"/>
                        <a href="tel:+78006009580">
                            8-800-600-95-80
                        </a>
                    </div>
                    <div>
                        бесплатный звонок по РФ
                    </div>
                </div>
                <div
                    className={style.menu}
                    style={visible ? { opacity: '0'} : {}}
                    onClick={this.showModal}>
                    <div className={style.menuIcon}>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
                <Modal
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleOk}
                    wrapClassName={sticky ? style.stickyModalMenu : `${style.modalMenu} ${marginModalClass}` }

                >
                    <ul className={style.menuList}>
                        <span className={style.close} onClick={this.handleOk}/>
                        <li><Link
                            offset={-50}
                            className='Steps'
                            to='Steps'
                            spy={true}
                            smooth={true}
                            duration={1000}
                            onClick={this.handleOk}
                        >Как получить займ</Link></li>
                        <li><Link
                            offset={-50}
                            className="CreditInfo"
                            to="CreditInfo"
                            spy={true}
                            smooth={true}
                            duration={1000}
                            onClick={this.handleOk}
                        >Условия</Link></li>
                        <li><Link
                            offset={-50}
                            className="Contacts"
                            to="Contacts"
                            spy={true}
                            smooth={true}
                            duration={1000}
                            onClick={this.handleOk}
                        >Контакты</Link></li>
                    </ul>
                </Modal>
                <Modal
                    visible={visibleForm}
                    onOk={this.handleOk}
                    onCancel={this.handleOk}
                    wrapClassName={style.modalMenu}
                    destroyOnClose={true}
                >
                    <SendModalForm
                        formMetricsId={''}
                        handleClose={this.handleOk}/>
                </Modal>
            </header>
        );
    }
}

export default Header;
