
export function parseQueryString( queryString ) {
    let params = {}, queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.slice(queryString.indexOf('?') + 1).split('&');
    // Convert the array of strings into an object
    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }
    return params;
}

export function getRandomInt(min, max = min + 1000) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
