import React, { Component } from 'react';

import style from './index.module.sass';
import Button from '../Button'

class Success extends Component {
    render() {
        return (
            <section className={style.Success}>
                <h2>СПАСИБО ЗА ОБРАЩЕНИЕ В НАШУ КОМПАНИЮ!</h2>
                <h4>Ваша заявка успешно принята!</h4>
                <p>
                    В ближайшее время с Вами свяжется наш сотрудник для уточнения условий предоставления займа.
                </p>
                <div onClick={() => this.props.close()}>
                    <Button styleName={'default'} >ОК</Button>
                </div>
            </section>
        );
    }
}

export default Success;
