import React, {Component} from 'react';
import { Modal } from 'antd';

import SendModalForm from "../SendModalForm";

import style from './index.module.sass';
import send from '../../assets/imgs/send.png'
import go from '../../assets/imgs/go.png'
import setLoan from '../../assets/imgs/setLoan.png'
import getLoan from '../../assets/imgs/getLoan.png'
import arrow from '../../assets/imgs/circleArrow.png'
import eagle from '../../assets/imgs/eagle.png'
import eagleWebp from '../../assets/imgs/webp/eagle.webp'

import icon_dogovor from '../../assets/imgs/dogovor.svg'
import icon_mail from '../../assets/imgs/mail.svg'
import icon_office from '../../assets/imgs/office.svg'
import icon_zaim from '../../assets/imgs/zaim.svg'

class Steps extends Component {
    state = {
        visible: false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showModalForm = () => {
        this.setState({
            visibleForm: true,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
            visibleForm: false
        });
    };

    steps = [
        {
            logo: icon_mail,
            header: 'Отправить заявку',
            content: <p>Оставьте онлайн заявку для точной оценки автомобиля и определения суммы займа.</p>,
            handleLink: this.showModalForm
        },
        {
            logo: icon_office,
            header: 'Приехать в офис',
            content: <p>Ознакомиться с условиями займа.<br />Показать автомобиль.</p>
        },
        {
            logo: icon_dogovor,
            header: 'Заключить договор',
            content: <p>{'Заключаем договор займа строго в соответствии ЦБ РФ.\n'}<br/><a href={'/files/Общие%20условия%20договора%20потребительского%20микрозайма.pdf'} target="_blank" rel="noopener noreferrer">Общие условия микрозайма</a></p>
        },
        {
            logo: icon_zaim,
            header: 'Получить займ',
            content: <p>Получите деньги моментально наличными сразу при подписании договора.</p>
        },
    ];

    render() {

        return (
            <div className={style.Steps}>
                <ul>
                    {this.steps.map(({logo, header, content, handleLink = false}, i) => {
                        return <li key={i}>
                            <img src={logo} alt=""/>
                            <article className={style.text}>
                                <header className={handleLink ? style.textLink : ''} onClick={handleLink || (() => null)}>{header}</header>
                                {content}
                            </article>
                        </li>
                    })}
                </ul>
                <div>
                    <img src={arrow} alt=""/>
                    <picture>
                        <source type={"image/webp"}
                                srcSet={`${eagleWebp}`}/>
                        <img src={eagle} alt="ЦБ РФ"/>
                    </picture>
                    <p>
                        Состоим в реестре ЦБ РФ<br />
                        (Банка России)
                    </p>
                </div>
                <Modal
                    visible={this.state.visibleForm}
                    onOk={this.handleOk}
                    onCancel={this.handleOk}
                    wrapClassName={style.modalMenu}
                >
                    <SendModalForm
                        handleClose={this.handleOk}/>
                </Modal>
            </div>
        );
    }
}

export default Steps;
