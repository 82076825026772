import React, { Component } from 'react';
import {
    Form, InputNumber, Modal, Icon
} from 'antd';
import phoneFormatter from 'phone-formatter'
import axios from 'axios'

import Button from '../Button'

import style from './index.module.sass';
import Success from "../Success";
import DataAgreement from "../DataAgreement";
import {parseQueryString} from "../../utils";

const multiLabels = {
    '1': 'ЗАЛОЖИТЬ ПТС',
    '2': 'ЗАЛОЖИТЬ ПТС',
    '3': 'ПОЛУЧИТЬ ДЕНЬГИ',
    '4': 'ПОЛУЧИТЬ ДЕНЬГИ',
    '5': 'ПОЛУЧИТЬ ДЕНЬГИ',
    '6': 'ЗАЛОЖИТЬ ПТС',
    '7': 'ПОЛУЧИТЬ ЗАЙМ',
    '8': 'ПОЛУЧИТЬ ЗАЙМ',
    '9': 'ПОЛУЧИТЬ ДЕНЬГИ',
    '10': 'ПОЛУЧИТЬ ДЕНЬГИ',
    '11': 'ЗАЛОЖИТЬ ПТС',
    '12': 'ПОЛУЧИТЬ ДЕНЬГИ',
    '13': 'ЗАЛОЖИТЬ ПТС',
};

class InputForm extends Component {
    state = {
        error: false,
        message: '',
        loading: false,
        visible: false,
        visibleAgreement: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (window.sendYMReachGoal) {
            window.sendYMReachGoal('clik');
        }

        this.props.form.validateFields((err, {phone}) => {
            let phoneIsValid = false;
            if  (phone) {
                if  ((phone.toString().length === 10) && (phone.toString()[0] !== '8')) {
                    phoneIsValid = true;
                }
            }
            if (!err && phoneIsValid) {
                this.setState({
                    loading: true
                });
                console.log('Received values of form: ', phone);
                axios.post('/api/application', { phonenumber: phone })
                    .then((response) => {
                        let data = response.data;
                        if (data.error) {
                            this.setState({
                                error: true,
                                message: data.message || 'Что-то пошло не так. Попробуйте повторно отправить заявку.',
                                loading: false
                            })
                        } else if (data.success) {
                            this.setState({
                                loading: false
                            });

                            this.showModal();
                            this.props.form.resetFields();

                            let sendDataMetricsCallback = function() {
                                console.log('Запрос в Яндекс Метрику успешно отправлен!');
                            };

                            let { formMetricsId = '' } = this.props;

                            if (window.sendDataMetrics) {
                                window.sendDataMetrics({ phonenumber: phone }, sendDataMetricsCallback, formMetricsId)
                            } else {
                                console.error('sendDataMetrics не найден!');
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            error: true,
                            message: "Ошибка отправки данных. Попробуйте повторно отправить форму. ",
                            loading: false
                        })
                    });
            } else {
                this.setState({
                    error: true,
                    message: ''
                })
            }
        });
    };

    handleChange = () => {
        this.setState({
            error: false,
            message: ''
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showAgreementModal = () => {
        this.setState({
            visibleAgreement: true,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
            visibleAgreement: false
        });
    };

    render() {
        const {
            getFieldDecorator
        } = this.props.form;

        const { customFormClass = '' } = this.props;
        const { error, message } = this.state;

        const form = this.props.form;
        const { handleSuccess = () => null } = this.props;

        const handleOk = () => {
            handleSuccess();
            this.handleOk();
        }

        let url = document.createElement('a');
        url.href = window.location.href;
        let urlParams = parseQueryString(url.search);
        let btnCaption = 'ОСТАВИТЬ ЗАЯВКУ';
        if (urlParams.utm_multi && multiLabels[urlParams.utm_multi]) {
            btnCaption = multiLabels[urlParams.utm_multi];
        }
        return (
                <Form
                    layout="inline"
                    className={`${style.form} ${customFormClass}`}
                    onSubmit={this.handleSubmit}>
                    <Form.Item validateStatus={error ? 'error' : 'success'}>
                        {getFieldDecorator('phone', {
                            rules: [{ required: true, message: 'Пожалуйста, введите свой номер телефона!' }],
                        })(

                            <InputNumber
                                className={'whiteInput'}
                                placeholder={'Ваш номер телефона'}
                                formatter={value => {
                                    if (value.toString().length === 0) {
                                        return value;
                                    }
                                    value = value.toString().replace('@', '');
                                    let newValue = '';
                                    value += '';
                                    console.log('Formatter value:', value);
                                    console.log('phoneFormatter value:', phoneFormatter.format(value + '', 'NNN NNN NN NN').replace(/N/g, ''));
                                    if (value.length > 10) {
                                        newValue = isNaN(+(value.substring(0, 10))) ? value.substring(0, 10) : +(value.substring(0, 10));
                                        console.log('Formatter newvalue:', newValue);
                                        return `+7 ${newValue}`
                                    } else {
                                        if ((value[0] === '8') || (value[0] === '7')) {
                                            console.log('Formatter works');
                                            return '+7 ';
                                        }
                                        if (value === ' ') {
                                            console.log('!value');
                                            return '+7 '
                                        }
                                        console.log('FORMAT',`+7 ${phoneFormatter.format(value, 'NNN NNN NN NN').replace(/N/g, '')}`.replace(/\s+$/, ""));

                                        return '+7 ' + `${phoneFormatter.format(value, 'NNN NNN NN NN').replace(/N/g, '')}`.replace(/\s+$/, "")
                                    }
                                }}
                                parser={value => {
                                    console.log('parser', value);
                                    value = value.replace(/\s/g, '');
                                    const regexp = /\+7\s?|[^0-9]/g;
                                    if (!value.length) {
                                        return value
                                    } else {
                                        if (+value.replace(regexp, '').length > 10) {
                                            value = value.replace(regexp, '');
                                            let newValue = isNaN(+(value.substring(0, 10))) ? value.substring(0, 10) : +(value.substring(0, 10));
                                            return newValue
                                        }
                                        return value.replace(regexp, '')
                                    }
                                }}
                                onChange={this.handleChange}
                                onFocus={() => {
                                    if (!form.getFieldValue("phone")) {
                                        form.setFieldsValue({
                                            "phone": "@"
                                        })
                                    }
                                    this.setState({
                                        focusNumber: true
                                    })
                                }}
                                onBlur={() => {
                                    console.log(form.getFieldValue("phone"));
                                    if (form.getFieldValue("phone") == " ") {
                                        form.setFieldsValue({
                                            "phone": " "
                                        });
                                        this.setState({
                                            focusNumber: false
                                        })
                                    }
                                    if (!form.getFieldValue("phone")) {
                                        this.setState({
                                            focusNumber: false
                                        })
                                    }

                                }}
                            />
                        )}
                    </Form.Item>
                    <div>
                        <Button styleName={'default'}
                                className={this.state.loading ? style.disabledButton : ''}
                        >{this.state.loading ? <Icon type="loading" /> : btnCaption}</Button>
                        <p>
                            Нажимая кнопку, вы соглашаетесь<br/>
                            с <span onClick={this.showAgreementModal}>правилами</span> обработки персональных данных
                        </p>
                    </div>
                    <Modal
                        visible={this.state.visible}
                        onOk={handleOk}
                        onCancel={handleOk}
                        wrapClassName={style.modalMenu}
                    >
                        <Success close = {handleOk}/>
                    </Modal>
                    <Modal
                        visible={this.state.visibleAgreement}
                        onOk={this.handleOk}
                        onCancel={this.handleOk}
                    >
                        <DataAgreement close = {this.handleOk}/>
                    </Modal>
                </Form>
        );
    }
}

const WrappedInputForm = Form.create()(InputForm);

export default WrappedInputForm;
