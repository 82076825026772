import React, { Component } from 'react';
import { BackTop, Icon } from 'antd';

import Header from '../Header'
import Loans from '../Loans'
import Notificator from '../Notificator'

import style from './index.module.sass';
import Stats from '../Stats';
import {items} from '../../items'
import SectionWrapper from "../SectionWrapper";
import Info from "../Info";

import { Element } from 'react-scroll';

import { getRandomInt } from '../../utils'
import whatsapp from '../../assets/imgs/wa.png';
import telegram from '../../assets/imgs/tg.png';

class App extends Component {
    constructor(props) {
        super(props);


        if (!window.localStorage) {
            window.localStorage = {
                getItem: () => null,
                setItem: () => null,
            }
        }

        let abSequence = 1;//+localStorage.getItem('ab_sequence');
        console.log('constructor abSequence:',abSequence);
        if (!abSequence) {
            abSequence = getRandomInt(1,2);
            localStorage.setItem('ab_sequence',abSequence);
        }

        window.sequence = (abSequence === 1) ? 'k-top' : 'k-bottom';
        this.state = {
            notification: false,
            abSequence
        };

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentDidMount() {
        if (window.sendABTestMetrics) {
            window.sendABTestMetrics({ sequence: window.sequence }, () => console.log(window.sequence, 'was send'), window.sequence)
        } else {
            console.error('sendABTestMetrics не найден!');
        }
    }

    closeNotification() {
        this.setState({
            notification: false
        });
    };

    render() {
        let { notification, abSequence } = this.state;
        let notificationElement = notification ?
            <Notificator onClose={this.closeNotification}>
                Каждому клиенту ставка 3,5% в месяц
            </Notificator>
         : <div />;
        console.log('ab_sequence', abSequence)


        let outputItems = []
        if (abSequence === 2) {
            outputItems.push(<Element key={0} name={items[0].name}>
                <SectionWrapper  header={items[0].header} >{items[0].component}</SectionWrapper>
            </Element>)
        }
        outputItems.push(<Element name={"Calc"} className={style.Calc}><div className={style.anchor} id={"calc"}></div> <SectionWrapper  header={"Рассчитать платёж"} ><Stats/></SectionWrapper></Element>)

        items.forEach(function(item, idx){
            if (abSequence == 2 && item.name === 'Steps') {
                return
            }
            outputItems.push(<Element key={idx} name={item.name} >
                <SectionWrapper  header={item.header} >{item.component}</SectionWrapper>
            </Element>)
        });
        return (
            <div className={style.App}>
                {notificationElement}
                <Header visibleNotification={notification}/>
                <Element name={'Loans'} className={style.Loans} >
                    <Loans/>
                </Element>
                {outputItems}
                <Info/>
                {/*<BackTop>*/}
                {/*    <div className={style.mover}>*/}
                {/*        <Icon type="arrow-up" theme="outlined" />*/}
                {/*    </div>*/}
                {/*</BackTop>*/}
                <div className={style.messengers}>
                    <a href="https://wa.me/79217826164" target="_blank"><img src={whatsapp} /></a>
                    <a href="https://t.me/creditcar_pod_pts" target="_blank"><img src={telegram} /></a>
                </div>
            </div>
        );
    }
}

export default App;
