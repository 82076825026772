import React from 'react';

import InputForm from "../InputForm";

import style from './index.module.sass';

function SupportBlock({ formMetricsId = '' }) {
        return (
            <section className={style.SupportBlock}>
                <div id={"still-questions"} className={style.anchor}></div>
                <article>
                    <h2>Остались вопросы?</h2>
                    <p>
                        Отправьте свои контактные<br />
                        данные, и мы сразу вам перезвоним
                    </p>
                </article>
                <InputForm
                    customFormClass={style.customForm}
                    formMetricsId={formMetricsId}
                />
            </section>
        );
}


export default SupportBlock;
