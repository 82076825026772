import React, {Component} from 'react';

import style from './index.module.sass';
import ReactPlayer from 'react-player/youtube';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 600 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 600, min: 460 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 460, min: 0 },
        items: 1,
    }
};

class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.testimonialsRef = React.createRef();
    }    

    videos = [
        '/video/001.mp4',
        '/video/002.mp4',
        '/video/003.mp4',
        '/video/004.mp4',
        '/video/005.mp4',
        '/video/006.mp4',
        '/video/007.mp4',
        '/video/008.mp4',
        '/video/009.mp4',
        '/video/010.mp4',
        '/video/011.mp4',
        '/video/012.mp4',
        '/video/013.mp4',
        '/video/014.mp4',
        '/video/015.mp4',
        '/video/016.mp4',
    ];

    render() {
        return (
            <div className={style.Testimonials} ref={this.testimonialsRef}>
                <div id={"testimonials"} className={style.anchor}></div>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    // removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {this.videos.map((video, i) => (
                        <div className={style.video} key={i}>
                            <div>
                                <video controls>
                                    <source src={video} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        );
    }
}

export default Testimonials;