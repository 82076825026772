import React, {PureComponent} from 'react';
import {Slider, Form, InputNumber } from 'antd';
import currencyFormatter from 'currency-formatter';

import style from './index.module.sass';
import Button from "../Button";


class CreditTermItem extends PureComponent {
    constructor(props) {
        super(props);
        console.log('props', props);
        const {
            type = 'initial',
            initValue = 0,
            minValue = 0,
            maxValue = 1,
            step = 1,
            itemTab = '',

        } = props;

        const format = this.termTypesFunc[type] ? this.termTypesFunc[type] : 'initial';
        const validType = this.termTypesFunc[type] ? type : 'initial';
        this.state = {
            type: validType,
            value: initValue,
            minValue,
            maxValue,
            step,
            format,
            month: (type === 'period') ? 'месяцев' : '',
            scale: this.calcScale(+initValue, maxValue, minValue)
        };

        this.inputId = 'number' + itemTab;

        console.log('this.state', this.state);
    }

    componentDidMount() {
        if (this.props.type === 'period') {
            //setTimeout(() => ;
            this.setMonth(this.props.initValue);
        }
    }

    calcScale = (val, maxValue, minValue) => +((100 * (+val - minValue)) / (maxValue - minValue)).toFixed(2);

    termTypesFunc = {
        cost: (val) => currencyFormatter.format(+val, {
            code: 'RUB',
            thousand: ' ',
            precision: 0,
            decimal: ''
        }),
        initial: (val) => currencyFormatter.format(+val, {
            code: 'RUB',
            thousand: ' ',
            precision: 0,
            decimal: ''
        }) + '/мес.',
        period: (val, init = false) => {
            console.log('Period:', val + ' ' + this.state.month);
            return val + ' ' + this.state.month
        },
    };

    sliderChange = (val) => {
        console.log("step sum", val);
        const {minValue, maxValue} = this.state;

        let value = this.roundOffValue(((val * (maxValue - minValue)) / 100) + minValue);
        this.props.form.setFieldsValue({[this.inputId]: value});
        this.setState({
            scale: val,
            value
        });
        this.props.handleChangeVal(value);
        if (this.props.type === 'period') {
            //setTimeout(() => ;
            this.setMonth(value);
        }
    };

    timeoutMonthSetter = (val) => {
        if (val !== this.state.month) {
            setTimeout(() => {
                if (this.props.form.getFieldValue(this.inputId)) {
                    this.setState({
                        month: val
                    });
                }
            }, 1000);
        }
    };

    setMonth = (val) => {
        if (val) {
            let boolFlag = (((+val % 10) > 1) && ((+val % 10) < 5) && ((+val < 5) || (+val > 14))) || ((+val < 5) && (+val > 1));
            if (boolFlag) {
                this.timeoutMonthSetter('месяца')
            } else if (((+val % 10) !== 1) || (+val === 11)) {
                this.timeoutMonthSetter('месяцев');
            } else {
                this.timeoutMonthSetter('месяц');
            }
        }
    };


    numberChange = (val) => {
        console.log('numberChange: ', val);
        if (this.props.type === 'period') {
            //setTimeout(() => ;
            this.setMonth(val);
        }
        console.log('numberChange length: ', (val + '').length);

        const {minValue, maxValue} = this.state;
        if (val > maxValue) {
            console.log('1');
            this.setState({
                scale: 100
            });
            this.props.handleChangeVal(maxValue);
        } else if (val < minValue) {
            console.log('MIN VALUE', minValue);
            this.setState({
                scale: 0,
            });
            this.props.handleChangeVal(minValue);
        } else {
            console.log('SCALE', Math.round(((val - minValue) * 100) / maxValue));
            this.setState({
                scale: Math.round(((val - minValue) * 100) / (maxValue - minValue)),
            });
            this.props.handleChangeVal(val);
        }
    };

    numberKeyDown = (event) => {
        let key = event.keyCode || event.charCode;
        console.log('KEY EVENT', key, this.props.form.getFieldValue(this.inputId));


        if (key === 8 || key === 46) {
            let value = ('' + this.props.form.getFieldValue(this.inputId)).replace(/[^0-9]/g, '');
            console.log('SELECTION START', event.target.selectionStart, value, value.length);
            CreditTermItem.setCaretPosition(this.inputId, (value + '').length)
            if (event.target.selectionStart > value.length) {
                let newValue = (value.length !== 1) ? value.substring(0, value.length - 1) : '';
                console.log('(value.length !== 1)', (value.length !== 1), newValue);

                this.props.form.setFieldsValue({
                    [this.inputId]: newValue
                });

            }
        }

    };

    roundOffValue = (value) => {
        let {step, minValue} = this.state;
        if (value < minValue) value = minValue;
        console.log(value, step, Math.round(value / step));
        return Math.round(value / step) * step
    };

    static setCaretPosition(elemId, caretPos) {
        let elem = document.getElementById(elemId);

        if (elem != null) {
            if (elem.createTextRange) {
                let range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            }
            else {
                if (elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                }
                else
                    elem.focus();
            }
        }
    }

    createMarks = (type) => {

        let marks = {};
        let counter = 0;
        let step = 1;
        switch (type) {

            case 'cost':
                step = 2.22;
                while (counter < 100) {
                    marks[counter] = '';
                    counter += step;
                }
                marks[0] = currencyFormatter.format(+this.state.minValue, {
                    code: '',
                    thousand: ' ',
                    precision: 0,
                    decimal: ''
                });
                marks[100] = currencyFormatter.format(+this.state.maxValue, {
                    code: '',
                    thousand: ' ',
                    precision: 0,
                    decimal: ''
                });
                break;
            case 'period':
                step = +((100 / 12) + '').substring(0, 4);
                while (counter < 100) {
                    marks[counter] = '';
                    counter += step;
                }
                marks[100] = '';
                break;
            default:
                break;
        }
        return marks
    };


    render() {

        const {
            getFieldDecorator
        } = this.props.form;

        const form = this.props.form;

        const {
            header = 'Ежемесячный платеж',
            label = '',
            handleClickLink = false,
            itemTab = ''
        } = this.props;

        const {
            format, value, type, minValue, maxValue
        } = this.state;


        let marks = this.createMarks(type);

        let formItem = <Form.Item>
            {getFieldDecorator(this.inputId, {
                rules: [],
                initialValue: value
            })(
                <InputNumber
                    min={0}
                    formatter={(value) => {
                        console.log('FORMAT', value);
                        /*if (value > maxValue) {
                            return format((maxValue + '').replace(/[^0-9]/g, ''))
                        }*/

                        return format((value + '').replace(/[^0-9]/g, ''))
                    }}
                    parser={(value) => {
                        console.log('parser', value);
                        console.log('parser modified', (value + '').replace(/[^0-9]/g, ''));
                        let numberVal = +(value + '').replace(/[^0-9]/g, '');
                        if (numberVal > maxValue) {
                            return maxValue
                        }

                        return (value + '').replace(/[^0-9]/g, '')
                    }}
                    onBlur={() => {
                        if (form.getFieldValue(this.inputId) < minValue) {
                            form.setFieldsValue({
                                [this.inputId]: minValue
                            })
                        }
                    }}
                    onKeyDown={this.numberKeyDown}
                    onChange={this.numberChange}
                />
            )}
        </Form.Item>;

        return (
            <article className={(type === 'advert') ? '' : style.CreditTermItem}>
                <h4>{header}</h4>
                <div>
                    <Form>
                        {(type === 'initial') ? format(this.props.initValue) : formItem}
                    </Form>
                    {(type !== 'initial') ? <div className={style.slider}>

                        <Slider
                            marks={marks}
                            onChange={this.sliderChange}
                            tipFormatter={null}
                            step={0.2}
                            defaultValue={20}
                            value={this.state.scale}
                            // included={false}
                        />

                    </div>: ''}
                    {(type !== 'initial') ? <span className={style.finishCredit}>Досрочное погашение без комиссий и штрафных санкций.</span>: ''}
                    {(type === 'initial1') ? <span style={{width: "100%"}}
                        // onClick={handleClickLink ? () => handleClickLink(itemTab) : () => null}
                        // className={handleClickLink ? 'disabledLink' : ''/*itemTab !== activeTab ? style.linkLabel : (itemTab ? style.inactiveLink : '')*/}
                    >{label}</span> : ''}
                    {(type === 'initial') ? label : ''}
                    {(type == 'initial') ? <span className={style.finishCredit + ' ' + style.finishCredit2}>Досрочное погашение без комиссий и штрафных санкций.</span>: ''}
                    {(type === 'initial') ? <span className={'calcExample'}><sup>*</sup>&nbsp;пример расчета носит исключительно информационный характер и не является публичной офертой</span> : '' }

                    {(type === 'initial') && this.props.initValue >= 17500 &&
                    <div className={style.ServiceByTablet}>Услуга предоставляется ООО МКК "Капелла" и ООО МКК "Народная
                        опора"</div>
                    }
                </div>
                {(type === 'initial') && this.props.initValue >= 17500 &&
                <div className={style.ServiceBy}>Услуга предоставляется ООО МКК "Капелла" и ООО МКК "Народная опора"</div>
                }
            </article>
        );
    }
}

const WrappedCreditTermItem = Form.create()(CreditTermItem);

export default WrappedCreditTermItem;
