import React from 'react';

import InputForm from "../InputForm";

import style from './index.module.sass';
import {parseQueryString} from "../../utils";

const multiLabels = {
    '1': <h4>Заложить ПТС в Санкт-Петербурге</h4>,
    '2': <h4>Получить займ под залог ПТС в Санкт-Петербурге</h4>,
    '3': <h4>Получить деньги под ПТС в Санкт-Петербурге</h4>,
    '4': <h4>Получить ссуду под залог ПТС</h4>,
    '5': <h4>Получить микрозайм под залог ПТС</h4>,
    '6': <h4>Получить займ под залог ПТС</h4>,
    '7': <h4>Получить займ под залог ПТС</h4>,
    '8': <h4>Получить займ  под ПТС</h4>,
    '9': <h4>Получить деньги под залог ПТС</h4>,
    '10': <h4>Получить деньги под ПТС</h4>,
    '11': <h4>Получить кредит под залог ПТС в Санкт-Петербурге</h4>,
    '12': <h4>Получить кредит под ПТС в Санкт-Петербурге</h4>,
    '13': <h4>Заложить ПТС в Санкт-Петербурге</h4>,
};
function SendModalForm({ formMetricsId = '', handleClose,  }) {
    let url = document.createElement('a');
    url.href = window.location.href;
    console.log(
        url.href + '\n' +           // the full URL
        url.protocol + '\n' +       // http:
        url.hostname + '\n' +       // site.com
        url.port + '\n' +           // 81
        url.pathname + '\n' +       // /path/page
        url.search + '\n' +         // ?a=1&b=2
        url.hash                    // #hash
    );

    console.log(parseQueryString(url.search));
    let urlParams = parseQueryString(url.search);

    let headerName = <h4>Менеджер перезвонит вам в течение 15 минут</h4>;
    if (urlParams.utm_multi && multiLabels[urlParams.utm_multi]) {
        headerName = multiLabels[urlParams.utm_multi];
    }
    return (
        <section className={style.SendModalForm}>
            {headerName}
            <div
                className={style.closeButton}
                onClick={handleClose}>
                <div/>
                <div/>
            </div>
            <InputForm
                formMetricsId={formMetricsId}
                customFormClass={style.customForm}
                handleSuccess={handleClose}
            />
        </section>
    );

}

export default SendModalForm;
