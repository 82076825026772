import * as React from 'react';

import style from './index.module.sass';
import button from '../../assets/imgs/closeButton.png';

export default function Notificator(props) {
    return (
      <div id={'notificator'} className={style.notificator}>
        <div>{props.children}</div>
        <img src={button} onClick={props.onClose} alt="закрыть" />
      </div>
    );
}
