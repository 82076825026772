import React, { Component } from 'react';
import currencyFormatter from 'currency-formatter';
import { Modal } from 'antd';

import style from './index.module.sass';
import styleSupport from '../SupportBlock/index.module.sass';
import CreditTermItem from "../CreditTermItem";
import Button from "../Button";
//import Timetable from "../Timetable";
import {parseQueryString} from "../../utils";
import SendModalForm from "../SendModalForm";
import Countdown from 'react-countdown';

let url = document.createElement('a');
url.href = window.location.href;
console.log(parseQueryString(url.search));
let urlParams = parseQueryString(url.search);

const initPeriod =  urlParams.utm_source && urlParams.utm_source.toLowerCase() == "yandex" ? 1 : 2;
const initProcent = urlParams.utm_source && (urlParams.utm_source.toLowerCase() == "yandex" || urlParams.utm_source.toLowerCase() == "google") ? 1.5 : 3.5;
const textPeriod = initPeriod * 30 + 1;
const initSum = 100000;

// Renderer callback with condition
const padNumber = (number, caption) => {
    let digits = (number + '').length  == 2 ? number + '' : ('0' + number)
    return <div className={style.digits}><div className={style.digit}>{digits[0]}</div><div className={style.digit}>{digits[1]}</div><div className={style.digitCaption}>{caption}</div></div>;
}
const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
    } else {
        // Render a countdown
        return <div className={style.countdown}>
            <div className={style.countdownTitle}>До окончания акции осталось:</div>
            <div>
            {padNumber(days, "дней")}{padNumber(hours,"часов")}{padNumber(minutes, "минут")}{padNumber(seconds, "секунд")}
            </div>
        </div>;
    }
};


class Stats extends Component {
    constructor(props) {
        super(props);
        let allSum = this.calcAllSum(initSum, initProcent, initPeriod);
        this.state = {
            procent: initProcent,
            period: initPeriod,
            sum: initSum,
            allSum,
            paymentValue: this.calcSumForPeriod(initSum, initProcent),
            activeTab: '2',
            visible: false,
            visibleForm: false
        }
    }

    calcAllSum = (sum, procent, period) => sum + this.calcSumForPeriod(sum, procent) + this.calcSumForPeriod(sum, 7) * (period - 1);
    calcSumForPeriod = (sum, procent) => Math.ceil((sum * procent) / 100);

    handleChangeSum = (sum) => {
        let paymentValue = this.calcSumForPeriod(sum, initProcent);
        let allSum = this.calcAllSum(sum, initProcent, this.state.period);
        this.setState({
            sum,
            allSum,
            paymentValue
        })
    };

    handleChangePeriod = (period) => {
        console.log('period', this.calcSumForPeriod(this.state.sum, initProcent));

        let paymentValue = this.calcSumForPeriod(this.state.sum, initProcent);
        let allSum = this.calcAllSum(this.state.sum, initProcent, period);
        this.setState({
            period,
            allSum,
            paymentValue
        })
    };

    handleSwithTab = (tabKey) => {
        if ((tabKey === '1' || tabKey === '2') && tabKey !== this.state.activeTab) {
            this.setState({
                activeTab: tabKey
            })
        }
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    showModalForm = () => {
        this.setState({
            visibleForm: true,
        });
    };

    handleOkForm = (e) => {
        this.setState({
            visibleForm: false,
        });
    };

    render() {
        const { sum, allSum, paymentValue, procent, period, activeTab } = this.state;
        console.log('paymentValue', paymentValue);
        const curDate = new Date();
        return (
            <section className={style.Stats}>
                <CreditTermItem
                    type='cost'
                    header='Сумма займа'
                    label={'Досрочное погашение'}
                    minValue = {50000}
                    maxValue = {1000000}
                    initValue={initSum}
                    step={5000}
                    handleChangeVal ={this.handleChangeSum.bind(this)}
                    handleClickLink={this.handleSwithTab.bind(this)}
                    activeTab = {activeTab}
                    itemTab = {'1'}
                />
                {/*<CreditTermItem*/}
                {/*    type='period'*/}
                {/*    header={<div onClick={this.showModal} className={style.periodLink}>Срок займа<span>*</span></div>}*/}
                {/*    label={'График платежей'}*/}
                {/*    minValue = {initPeriod}*/}
                {/*    maxValue = {12}*/}
                {/*    initValue={initPeriod}*/}
                {/*    step={1}*/}
                {/*    handleChangeVal ={this.handleChangePeriod.bind(this)}*/}
                {/*    handleClickLink={this.handleSwithTab.bind(this)}*/}
                {/*    activeTab = {activeTab}*/}
                {/*    itemTab = {'2'}*/}
                {/*    />*/}
                <CreditTermItem
                    header='Ежемесячный платёж от:'
                    // label={<span style={{ whiteSpace: 'nowrap' }}>Процент переплаты {initProcent} % в первый месяц<br /> Всего вернуть {currencyFormatter.format(allSum, {
                    //     code: 'RUB',
                    //     thousand: ' ',
                    //     precision: 0,
                    //     decimal: ''
                    // })}</span>}
                    // label={<span style={{ whiteSpace: 'nowrap' }}><Button
                    label={<Button
                        className={style.calcButton}
                        style={{opacity: 1}}
                        onClick={() => this.showModalForm()}
                    >ПОЛУЧИТЬ ТОЧНЫЙ РАСЧЕТ &nbsp;</Button>}
                    initValue={paymentValue}
                    />
                <div className={styleSupport.SupportBlock + ' ' + style.advertBlock}  onClick = { () => window.showMarquiz() } >
                    <article>
                        <h2>Хотите займ дешевле?</h2>
                        <p>3 коротких вопроса о машине<br />и займ станет в 2 раза выгоднее!</p>
                    </article>
                    <div  className={style.advertBlockButton}>
                        <Button className={style.noblick_btn} href="#popup:marquiz_5dc578003509780044e4b93f" >ПОЛУЧИТЬ ВЫГОДУ</Button>
                        <p>Данное предложение ограничено<br />Воспользуйтесь финансовым преимуществом</p>
                    </div>
                    {/*<div  className={style.advertBlockButton}>*/}
                    {/*    <Button href="#popup:marquiz_5dc578003509780044e4b93f" >ПОЛУЧИТЬ ВЫГОДУ</Button>*/}
                    {/*    <Countdown date={Date.now() + (24*60*60*1000 - (curDate.getHours()*60*60+curDate.getMinutes()*60+curDate.getSeconds())*1000) + 24*3600*1000 } renderer={renderer}></Countdown>*/}
                    {/*    <div className={style.clear}></div>*/}
                    {/*    <div>Данное предложение ограничено<br />Воспользуйтесь финансовым преимуществом</div>*/}
                    {/*</div>*/}
                </div>

    {/*<Timetable
                    sum={sum}
                    allSum={allSum}
                    paymentValue={paymentValue}
                    procent={procent}
                    period={period}
                    activeTab={activeTab}
                />*/}

                <Modal
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleOk}
                >
                    <section className={style.info}>
                        <p>Срок от {textPeriod} дня до 12 месяцев возможен при пролонгации Договора. Пролонгация (продление) Договора микрозайма означает изменение срока действия договора потребительского займа и продление его на дополнительный срок. Погашение суммы займа и уплаты процентов на сумму займа в данном случае осуществляется на основании Изменений к Договору/Дополнительного соглашения/иных документов.</p>
                    <div onClick={this.handleOk}>
                        <Button styleName={'default'} >ОК</Button>
                    </div>
                    </section>
                </Modal>
                <Modal
                    visible={this.state.visibleForm}
                    onOk={this.handleOkForm}
                    onCancel={this.handleOkForm}
                    // wrapClassName={style.modalMenu}
                    destroyOnClose={true}
                >
                    <SendModalForm
                        formMetricsId={''}
                        handleClose={this.handleOkForm}/>
                </Modal>
            </section>
        );
    }
}

export default Stats;
