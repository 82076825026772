import React, {Component} from 'react';
import style from './index.module.sass';
import up from '../../assets/imgs/up.png'
import Tabber from "../Tabber";
import SupportBlock from "../SupportBlock";
import SendModalForm from "../SendModalForm";
import {Modal} from "antd";
import {parseQueryString} from "../../utils";

class CreditInfo extends Component {
    state = {
        visibleForm: false,
        formAlreadyShown: false
    };

    showModalForm = () => {
        this.setState({
            visibleForm: true,
            formAlreadyShown: true
        });
    };

    handleOk = (e) => {
        this.setState({
            visibleForm: false
        });
    };

/*
    componentDidMount() {

        if (window.pageYOffset > 1000) {
            if (!this.state.formAlreadyShown) this.showModalForm()
        }
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 1000) {
                if (!this.state.formAlreadyShown) this.showModalForm()
            }
        });

        window.addEventListener('scroll', () => {
            if (!this.state.formAlreadyShown) this.showModalForm()
        });
    }

 */


    componentDidMount(){
//        window.addEventListener('scroll', this.processModal);
        //if (!this.state.formAlreadyShown) this.showModalForm()

        window.addEventListener('scroll', () => {
            if (!this.state.formAlreadyShown &&  window.scrollY > this.prev) this.showModalForm()
            this.prev = window.scrollY;
        });

    }

    componentWillUnmount(){
//        window.removeEventListener('scroll', this.processModal);
    }

    render() {
        let url = document.createElement('a');
        url.href = window.location.href;
        console.log(parseQueryString(url.search));
        let urlParams = parseQueryString(url.search);
        let loanPeriod = urlParams.utm_source && urlParams.utm_source.toLowerCase() == "yandex" ? 31 : 61;

        const { visibleForm } = this.state;
        const textArray1 = [
            'Сумма займа от 50 000 до 1 000 000 р.',
            'Срок займа от '+ loanPeriod +' дня до 12 месяцев.',
            'Оставляете в компании только ПТС, сами уезжаете на автомобиле.',
            'Досрочное закрытие договора с перерасчетом в любой день.',
            'Не требуется переоформления автомобиля.',
            'Скрытых платежей, штрафов и комиссий нет.',
        ];

        const textArray2 = [
            'Возраст от 21 до 72 лет.',
            'Наличие у заемщика постоянной/временной регистрации в РФ.',
            'Гражданство в РФ.',
            'Иномарка не старше 15 лет, отечественные автомобили не старше 7 лет.',
            'Автомобиль должен быть зарегистрирован на территории РФ.',
            'Автомобиль не должен быть в кредите.',
        ];

        const textArray3 = [
            'Всего три документа',
            'Наличные сразу при подписании',
            'Не требуется подтверждать доход',
            'Не требуется согласие супруга'
        ];

        const leftContent = <div className={style.leftContent}>
            <ul>
                {textArray1.map((text, i) =>  <li key={i}><div />{text}</li>)}
            </ul>
        </div>;

        const rightContent = <div className={style.leftContent}>
            <ul>
                {textArray2.map((text, i) =>  <li key={i}><div />{text}</li>)}
            </ul>
        </div>;

        return (
            <div className={style.CreditInfo} ref={'creditInfo'} >
                <Tabber
                    leftHeaderText={'Условия получения займа'}
                    rightHeaderText={'Требования к заемщику'}
                    leftContentItem={leftContent}
                    rightContentItem={rightContent}
                />
                <div className={style.checkBlock}>
                    {
                        textArray3.map((text, i) => <article key={i}>
                            <img src={up} alt=""/>
                            <div>{text}</div>
                        </article>)
                    }

                </div>

                {/*<Modal*/}
                {/*    visible={visibleForm}*/}
                {/*    onOk={this.handleOk}*/}
                {/*    onCancel={this.handleOk}*/}
                {/*    wrapClassName={style.modalMenu}*/}
                {/*    destroyOnClose={true}*/}
                {/*>*/}
                {/*    <SendModalForm*/}
                {/*        handleClose={this.handleOk}/>*/}
                {/*</Modal>*/}

                <SupportBlock formMetricsId={''}/>
            </div>
        );
    }
}

export default CreditInfo;
