import React from 'react';

import Steps from "./components/Steps";
import CreditInfo from "./components/CreditInfo";
import About from "./components/About";
import Contacts from "./components/Contacts";
import Testimonials from "./components/Testimonials";

export const items = [
    {
        header: 'Отзывы клиентов',
        name: 'Testimonials',
        component: <Testimonials/>
    },
        {
        header: 'Как получить займ',
        name: 'Steps',
        component: <Steps/>
    },
    {
        header: 'Информация по займу',
        name: 'CreditInfo',
        component: <CreditInfo/>
    },
    {
        header: 'О компании',
        name: 'About',
        component: <About/>
    },
    {
        header: 'Контакты',
        name: 'Contacts',
        component: <Contacts/>
    },
];
