import React, { Component } from 'react';

import style from './index.module.sass';

class SectionWrapper extends Component {

    render() {
        const { header, children } = this.props;

        return (
            <section className={style.SectionWrapper}>
                <h2>{header}</h2>
                {children}
            </section>
        );
    }
}

export default SectionWrapper;
