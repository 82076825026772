import React, { Component } from 'react';


import Button from '../Button'

import style from './index.module.sass';

class DataAgreement extends Component {

    render() {
        return (
            <section className={style.DataAgreement}>

                <h2>Соглашение о передаче данных</h2>
                <p>
                    Я даю согласие ООО МКК «Народная опора», на обработку всех моих персональных данных, приведенных в заявке, и подтверждаю их принадлежность мне. Я даю согласие ООО МКК «Народная опора», на обработку моих персональных данных любыми способами, в том числе третьими лицами, в том числе воспроизведение, электронное копирование, обезличивание, блокирование, уничтожение, а также вышеуказанную обработку иных моих персональных данных, полученных в результате их обработки, с целью:<br />
                    — создания информационных систем персональных данных ООО МКК «Народная опора».<br />
                    — заключения договора займа с ООО МКК «Народная опора».<br />
                    а также в любых других целях, прямо или косвенно связанных с предоставлением займа и предложением иных продуктов ООО МКК «Народная опора».
                    <br/><br/>
                    Я даю согласие ООО МКК «Народная опора», на передачу персональных данных лицам, являющихся партнерами ООО МКК «Народная опора», в том числе платежным системам, расчетным небанковским кредитным организациям, коллекторским агентствам и обработку персональных данных такими партнерами с целью выдачи займа, предоставления электронных средств платежа и исполнения обязательств по договору займа.
                    <br/><br/>
                    Указанное согласие дано на срок 15 лет, а в случае его отзыва обработка моих персональных данных должна быть прекращена ООО МКК «Народная опора», и/или третьими лицами и данные уничтожены при условии расторжения договора займа и полного погашения задолженности по договору займа в срок не позднее 1 (одного) года с даты прекращения действия договора займа.<br/>
                    Отзыв указанного согласия осуществляется путем подачи в ООО МКК «Народная опора» письменного заявления.
                    <br/><br/>
                    Я даю свое согласие ООО МКК «Народная опора», на осуществление запроса о предоставлении из бюро кредитных историй (БКИ) и центрального каталога кредитных историй (ЦККИ) информации о моей кредитной историй в соответствии с Федеральным законом от 30.12.2004 № 218-ФЗ «О кредитных историях».
                    <br/><br/>
                    Я даю согласие на предоставление ООО МКК «Народная опора», всей имеющейся обо мне информации в объеме, в порядке и на условиях, определенных Федеральным законом «О кредитных историях» № 218-ФЗ от 30 декабря 2004 года, во все бюро кредитных историй, включенные в государственный реестр бюро кредитных историй.
                    <br/><br/>
                    Я даю согласие ООО МКК «Народная опора», на получение рекламных и информационных сообщений на указанный выше контактный номер, подтверждаю его принадлежность мне, а также даю согласие на обработку своих персональных данных в целях продвижения услуг ООО МКК «Народная опора», в том числе с использованием информационно-телекоммуникационных сетей.
                </p>
                <div onClick={() => this.props.close()}>
                    <Button styleName={'default'} >ОК</Button>
                </div>
            </section>
        );
    }
}

export default DataAgreement;
