// import * as React from 'react';
import React, {Component} from 'react';
import { Modal } from 'antd';

import doc1 from '../../assets/imgs/Свидетельство на ТЗ (Капелла).jpg';
import doc2 from '../../assets/imgs/Свидетельство о внесении сведений в государственный реестр микрофинансовых организаций.jpg';
import doc3 from '../../assets/imgs/Свидетельство о государственной регистрации юридического лица (МКК Капелла).jpg';
import doc4 from '../../assets/imgs/Свидетельство ООО МКК КАПЕЛЛА.jpg';
import showIcon from '../../assets/imgs/showDocument.png';
import style from './index.module.sass';


class DocsPanel extends Component {

  constructor(props, context = null) {
    super(props, context);
    this.state = {
      visibleDocument: false,
      title: '',
      file: ''
    };
  }

  docs = [
    {
      img: doc2,
      text: 'Свидетельство Банка России о внесении в реестр ЦБ'
    },
    {
      img: doc3,
      text: 'Свидетельство о регистрации юр лица ООО МКК «Капелла»'
    },
    {
      img: doc1,
      text: 'Свидетельство на товарный знак «Creditcar»'
    },
    {
      img: doc4,
      text: 'Свидетельство о членстве в Союзе «Микрофинансовый альянс «Институты развития малого бизнеса»'
    }
  ];

  showDocument = (title, file) => {
    this.setState({
      visibleDocument: true,
      title,
      file
    });
  }

  handleCancel = () => {
    this.setState({
      visibleDocument: false
    });
  }

  render() {
    return (
        <section className={style.docsPanel}>
          {this.docs.map(({ img, text }, i) => (
            <article key={i + text}>
              <div
                onClick={() => {
                  this.showDocument(text, img);
                }}
              >
                <img className={style.shower} src={showIcon} alt="open" />
                <img src={img} alt="" />
              </div>
              <span>{text}</span>
            </article>
          ))}
          <Modal
              visible={this.state.visibleDocument}
              onOk={this.handleCancel}
              onCancel={this.handleCancel}
              className={style.modalDocument}
          >
            <div className={style.document}>
              <h1>{this.state.title}</h1>
              <img src={this.state.file} alt="" />
            </div>
          </Modal>
        </section>
    );
  }
}

export default DocsPanel;
