import React from 'react';
import {
    Button,
} from 'antd';

import style from './index.module.sass';

const stylesClasses = {
    'default': style.default,
    'yellow': style.yellow,
    'orange': style.orange
};

export default function ({
                             children = 'Отправить',
                             styleName = 'default',
                             className = '',
                             ...props
                         }) {

    let styleClassName = stylesClasses[styleName] ? stylesClasses[styleName] : stylesClasses['default'];

    return (<Button className={`${style.button} ${styleClassName} ${className}`} htmlType="submit" {...props}>
        {children}
        <div className={style.blick}></div>
    </Button>)

}
